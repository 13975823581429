import React from "react";

const App = () => {
  return(
  <div>
    <h1>Yapım aşamasında</h1>
  </div>
  );
}

export default App;